<script setup lang="ts">
// COMPOSABLES
const { data: session } = useAuthUtils();
const { isHelpSlideoverOpen } = useDashboard();

const links = computed(() => [
    {
        id: 'brands',
        label: 'Brands',
        icon: commonIcons.brand,
        to: { name: 'brands' },
        tooltip: {
            text: 'Brands',
            shortcuts: ['G', 'B'],
        },
    },
    {
        id: 'models',
        label: 'Models',
        icon: commonIcons.model,
        to: { name: 'models' },
        tooltip: {
            text: 'Models',
            shortcuts: ['G', 'M'],
        },
    },
    {
        id: 'versions',
        label: 'Versions',
        icon: commonIcons.version,
        to: { name: 'versions' },
        tooltip: {
            text: 'Versions',
            shortcuts: ['G', 'V'],
        },
    },
    // TODO: to be expanded when there are multiple asset types
    {
        id: 'bus-assets',
        label: 'Bus Assets',
        icon: commonIcons.asset,
        to: { name: 'bus-assets' },
        exact: true,
        tooltip: {
            text: 'Bus Assets',
            shortcuts: ['G', 'A'],
        },
    },
    // TODO: to be expanded when there are multiple asset types
    {
        id: 'bus-dossiers',
        label: 'Bus Dossiers',
        icon: commonIcons.dossier,
        to: { name: 'bus-dossiers', query: { [QueryStringKey.AdminUser]: session.value?.id } },
        exact: true,
        tooltip: {
            text: 'Bus Dossier',
            shortcuts: ['G', 'D'],
        },
    },
    // TODO: to be expanded when there are multiple asset types
    {
        id: 'bus-factory-attributes',
        label: 'Bus Factory Attributes',
        icon: commonIcons.bfa,
        to: { name: 'bus-factory-attributes' },
        tooltip: {
            text: 'Bus Factory Attributes',
            shortcuts: ['G', 'F'],
        },
    },
    {
        id: 'auctions',
        label: 'Auctions',
        icon: commonIcons.auction,
        to: { name: 'auctions' },
        tooltip: {
            text: 'Auctions',
            shortcuts: ['G', 'A'],
        },
    },
    {
        id: 'auction-planner',
        label: 'Auction Planner',
        icon: commonIcons.auctionPlanner,
        to: { name: 'auction-planner' },
        tooltip: {
            text: 'Auction Planner',
            shortcuts: ['G', 'P'],
        },
    },
    {
        id: 'sales',
        label: 'Sales',
        icon: commonIcons.sales,
        to: { name: 'sales' },
        tooltip: {
            text: 'Sales',
            shortcuts: ['G', 'N'],
        },
    },
    {
        id: 'companies',
        label: 'Companies',
        icon: commonIcons.company,
        to: { name: 'companies' },
        tooltip: {
            text: 'Companies',
            shortcuts: ['G', 'C'],
        },
    },
    {
        id: 'users',
        label: 'Users',
        icon: commonIcons.user,
        to: { name: 'users' },
        tooltip: {
            text: 'Users',
            shortcuts: ['G', 'U'],
        },
    },
    {
        id: 'settings',
        label: 'Settings',
        to: { name: 'settings' },
        icon: 'i-heroicons-cog-8-tooth',
        tooltip: {
            text: 'Settings',
            shortcuts: ['G', 'S'],
        },
    },
]);

const footerLinks = [
    {
        label: 'Help & Support',
        icon: 'i-heroicons-question-mark-circle',
        click: (): void => {
            isHelpSlideoverOpen.value = true;
        },
    },
];

const groups = computed(() => [
    {
        key: 'links',
        label: 'Go to',
        commands: links.value.map(link => ({ ...link, shortcuts: link.tooltip?.shortcuts })),
    },
]);
</script>

<template>
    <UDashboardLayout>
        <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
            <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
                <template #left>
                    <FleequidLogo size="md" />
                </template>
            </UDashboardNavbar>

            <UDashboardSidebar>
                <template #header>
                    <UDashboardSearchButton />
                </template>

                <UDashboardSidebarLinks :links="links" />

                <!-- <UDivider /> -->

                <div class="flex-1" />

                <UDashboardSidebarLinks :links="footerLinks" />

                <UDivider class="sticky bottom-0" />

                <template #footer>
                    <UserDropdown />
                </template>
            </UDashboardSidebar>
        </UDashboardPanel>

        <slot />

        <HelpSlideover />

        <ClientOnly>
            <LazyUDashboardSearch :groups="groups" />
        </ClientOnly>
    </UDashboardLayout>
</template>
